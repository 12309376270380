import React, { useState, useEffect, useRef } from 'react';
import data from '../projectList.json';
import { useNavigate } from 'react-router-dom';
import ChromaHeader from 'components/ChromaHeader';
import { BsArrowDownCircleFill } from 'react-icons/bs';
import { IconContext } from 'react-icons';
import ProjectListing from 'components/ProjectListing';
const breakPoint = 600;

const NavArrow = ({ arrowOpacity, onClick }) => {
  return (
    <div
      style={{
        position: 'fixed',
        display: 'flex',
        width: '100vw',
        zIndex: 3
      }}
    >
      <div style={{ flexGrow: 1 }} />
      <div
        style={{
          margin: '-2rem',
          opacity: arrowOpacity
        }}
        onClick={onClick}
      >
        <IconContext.Provider
          value={{
            color: '#ff0062',
            size: '5rem'
          }}
        >
          <BsArrowDownCircleFill />
        </IconContext.Provider>
      </div>
      <div style={{ flexGrow: 1 }} />
    </div>
  );
};

const Home = () => {
  const [scrollTop, setScrollTop] = useState(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isSmol, setIsSmol] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [arrowOpacity, setArrowOpacity] = useState(1);

  const containerRef = useRef();
  const projectNavigationRef = useRef();

  let styles = {
    ...defaultStyles,
    scriptHeader: {
      ...defaultStyles.scriptHeader,
      fontSize: isSmol ? '7rem' : '10rem'
    }
  };

  useEffect(() => {
    const width = window.innerWidth;
    setHeight(window.innerHeight);
    setWidth(width);
    setIsSmol(width <= breakPoint);
    window.addEventListener(
      'resize',
      () => {
        const width = window.innerWidth;
        setIsSmol(width <= breakPoint);
        setHeight(window.innerHeight);
        setWidth(width);
      },
      false
    );
  }, []);

  const onScroll = (e) => {
    setScrollTop(containerRef.current.scrollTop);
    setIsScrolling(containerRef.current.scrollTop > scrollTop);
    let proposedValue = containerRef.current.scrollTop / 1000;
    proposedValue = proposedValue > 1 ? 1 : proposedValue;
    const invertRange = (num, min, max) => max + min - num;
    setArrowOpacity(invertRange(proposedValue, 0, 1));
  };

  return (
    <div style={styles.wrapper} ref={containerRef} onScroll={onScroll}>
      <NavArrow
        arrowOpacity={arrowOpacity}
        onClick={() => {
          projectNavigationRef.current.scrollIntoView({ behavior: 'smooth' });
        }}
      />
      <div
        style={{
          position: 'fixed',
          height: '100rem',
          opacity: 0.3,
          marginTop: '-25rem auto 0 auto',
          backgroundImage: 'url(assets/img/texture.png'
        }}
      >
        <img alt="texture" src="assets/img/texture.png" />
      </div>
      <div>
        <div style={styles.topDash} />
        <div style={styles.container}>
          <div
            style={{
              ...styles.chroma,
              height: '20rem',
              left: isSmol ? '2rem' : '-2rem',
              mixBlendMode: 'screen',
              margin: '-2rem'
            }}
          >
            <div style={styles.scriptHeader}>
              Intrepid
              <br /> Pixel
            </div>
          </div>

          <ChromaHeader style={{ transform: 'rotateZ(-1deg)' }}>
            Wishes You Were Here!
          </ChromaHeader>

          <div style={{ display: 'flex', maxWidth: '100vw' }}>
            <div style={{ flexGrow: 1 }} />
            <div style={styles.bodyCopy}>
              <p>
                We have a tendency to refer to online communities and digital
                networks in geographic terms: the digital expands, grows,
                manifests. It's where we meet, something we visit. We forget
                that geography makes a distinction between <i>place</i> and
                <i>space</i>.
              </p>
              <p>
                Spaces exist physically, as does the internet: it's a series of
                tubes, datacenters, servers and power plants. Places also exist,
                but in our own minds. They are unfoldings in time which require
                performance. They are events. Software is an event. Your network
                is an event. It does not exist without your participation and
                observation.
              </p>
              <p>
                The containers which hold these events are kinetic energy,
                events in motion which have spun out of the potential of the
                people who gathered at a given moment. These artifacts of a
                roadside picnic can be bought, sold, built and destroyed, but
                the communities which do the work are always slightly out of
                sync, spilling over the boundaries, unruly and unexpected.
              </p>
              <p>
                This site is a collection of projects and essays which celebrate
                this place-which-is-not-a-place.
              </p>
              <p>
                Of course we "lost" this network - it never really existed. But
                it might still come into view in our peripheral vision, fragile
                and ephemeral and never where we left it last.
              </p>
              <p style={styles.footerLink}>
                A{' '}
                <a href="https://feralresearch.org/intrepid-pixel/">
                  Feral Research
                </a>{' '}
                project – Dec 31, 2022 – Lausanne Switzerland
              </p>
            </div>
            <div style={{ flexGrow: 1 }} />
          </div>

          <ChromaHeader style={{ transform: 'rotateZ(1deg)' }}>
            A Collection Of Zines, Projects & Ephemera from Places That Don't
            Exist.
          </ChromaHeader>
        </div>

        <div style={styles.bottomDash} />
        <div ref={projectNavigationRef}>
          <ProjectListing />
        </div>
      </div>
    </div>
  );
};
export default Home;

const defaultStyles = {
  bodyCopy: {
    color: 'white',
    maxWidth: '90vw',
    fontFamily: 'BasierRegular'
  },
  itemTitle: {
    color: 'white',
    maxWidth: '90vw',
    fontFamily: 'BasierRegular',
    margin: '1rem auto 0 auto',
    textTransform: 'uppercase',
    fontSize: '.8rem',
    fontWeight: 900
  },
  icon: {
    color: 'white',
    size: '2rem'
  },
  input: {
    appearance: 'none',
    color: '#8900f2',
    background: '#0000003b',
    margin: '1rem 0 0 0',
    width: 'calc(100% - 1rem)',
    height: '2rem',
    padding: '0.5rem',
    fontSize: '.8rem',
    fontWeight: 900,
    borderRadius: '0.3rem',
    border: '1px solid white'
  },
  wrapper: {
    position: 'relative',
    maxWidth: '100%',
    minHeight: '100vh',
    maxHeight: '100vh',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  container: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    width: '600px',
    margin: '0 auto 0 auto',
    lineHeight: '2rem'
  },
  chroma: {
    position: 'relative',
    backgroundImage: "url('assets/img/chroma.png')",
    padding: 0,
    margin: 0,
    backgroundSize: 'contain'
  },
  header: {
    color: 'white',
    background: 'black',
    mixBlendMode: 'overlay',
    fontSize: '3rem',
    lineHeight: '3.5rem',
    fontFamily: 'AuthenticSans',
    fontWeight: 900,
    maxWidth: '100vw'
  },
  scriptHeader: {
    position: 'relative',
    left: '1rem',
    color: 'white',
    background: 'black',
    mixBlendMode: 'overlay',
    fontSize: '10rem',
    lineHeight: '10rem',
    height: '10rem',
    fontFamily: 'Dancing Script',
    fontWeight: 900
  },
  projectListBackground: {
    opacity: 0.1,
    margin: 'auto',
    borderTop: '3px dotted black',
    position: 'absolute',
    transform: 'rotateZ(2deg)',
    left: '-31rem',
    minHeight: '100vh',
    width: '200vw',
    background: 'white',
    backgroundSize: 'cover',
    backgroundPosition: '-2rem -4rem',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(assets/img/e99a6961-0410-4429-a944-fb1909e6ef9e.png)'
  },
  projectList: {
    margin: 'auto',
    marginTop: '4rem'
  },
  footerLink: {
    fontWeight: 900,
    color: '#670029',
    textTransform: 'uppercase',
    fontSize: '.8rem'
  },
  topDash: {
    borderBottom: '3px dashed white',
    width: '100vw',
    margin: '1rem 0 7rem -11rem',
    transform: 'rotateZ(-3deg)'
  },
  bottomDash: {
    borderBottom: '3px dashed white',
    width: '100vw',
    margin: '7rem 0 8rem -11rem',
    transform: 'rotateZ(3deg)'
  }
};
