const ChromaHeader = (props) => {
  return (
    <div style={{ ...styles.chroma, ...props.style }}>
      <div style={styles.header}>{props.children}</div>
    </div>
  );
};

export default ChromaHeader;

const styles = {
  chroma: {
    position: 'relative',
    backgroundImage: "url('assets/img/chroma.png')",
    padding: 0,
    margin: 0,
    backgroundSize: 'contain'
  },
  header: {
    color: 'white',
    background: 'black',
    mixBlendMode: 'overlay',
    fontSize: '3rem',
    lineHeight: '3.5rem',
    fontFamily: 'AuthenticSans',
    fontWeight: 900,
    maxWidth: '100vw'
  }
};
