import React, { useState, useEffect, useRef } from 'react';
import ChromaHeader from 'components/ChromaHeader';
import ProjectNavigation from 'components/ProjectNavigation';
const breakPoint = 600;

const Code = () => {
  const [isSmol, setIsSmol] = useState(false);
  const containerRef = useRef();

  let styles = {
    ...defaultStyles,
    scriptHeader: {
      ...defaultStyles.scriptHeader,
      fontSize: isSmol ? '7rem' : '10rem'
    },
    pageChromaHeader: {
      ...defaultStyles.chroma,
      height: '20rem',
      left: isSmol ? '2rem' : '-2rem',
      mixBlendMode: 'screen',
      margin: '-2rem'
    }
  };

  useEffect(() => {
    const width = window.innerWidth;
    setIsSmol(width <= breakPoint);
    window.addEventListener(
      'resize',
      () => {
        const width = window.innerWidth;
        setIsSmol(width <= breakPoint);
      },
      false
    );
  }, []);

  return (
    <div style={styles.wrapper} ref={containerRef}>
      <div style={styles.background}>
        <img alt="texture" src="assets/img/texture.png" />
      </div>
      <div>
        <div style={{ position: 'fixed', top: 0, zIndex: 5 }}>
          <ProjectNavigation />
          <div style={styles.topDash} />
        </div>
        <div style={styles.container}>
          <div style={styles.pageChromaHeader}>
            <div style={styles.scriptHeader}>Code</div>
          </div>

          <ChromaHeader style={{ transform: 'rotateZ(-1deg)' }}>
            Make It Go
          </ChromaHeader>

          <div style={{ display: 'flex', maxWidth: '100vw' }}>
            <div style={{ flexGrow: 1 }} />
            <div style={styles.bodyCopy}>
              <div style={styles.item}>
                <div style={styles.itemImg}>
                  <img
                    alt=""
                    style={{ width: '5rem', height: '5rem' }}
                    src="https://gitlab.com/uploads/-/system/project/avatar/35106671/Screen_Shot_2022-04-05_at_5.03.43_PM.png?width=64"
                  />
                </div>
                <div style={{ textTransform: 'uppercase', fontWeight: 900 }}>
                  Dither
                </div>
              </div>
              [[Source](https://gitlab.com/anfaux/dither)] [[Front
              End](https://dither.foxriot.com/)] Dithers posted images `POST:
              https://api.foxriot.com/dither/atkinson (Atkinson Dither)`
              <div style={styles.item}>
                <div style={styles.itemImg}>
                  <img
                    style={{ width: '5rem', height: '5rem' }}
                    alt=""
                    src="https://gitlab.com/uploads/-/system/project/avatar/30796047/Screen_Shot_2021-10-26_at_10.51.28_PM.png?width=64"
                  />
                </div>
                <div style={{ textTransform: 'uppercase', fontWeight: 900 }}>
                  PixelProxy
                </div>
              </div>
              [[Source](https://gitlab.com/anfaux/pixel-proxy)] Converts data to
              video. Requires auth key (ask me!)
              `https://api.foxriot.com/pixelproxy?auth=XXX&url=https://example.com`
              <div style={styles.item}>
                <div style={styles.itemImg}>
                  <img
                    style={{ width: '5rem', height: '5rem' }}
                    alt=""
                    src="https://gitlab.com/uploads/-/system/project/avatar/30974393/Screen_Shot_2021-11-02_at_5.39.03_PM.png?width=64"
                  />
                </div>
                <div style={{ textTransform: 'uppercase', fontWeight: 900 }}>
                  PixelFont
                </div>
              </div>
              [[Source](https://gitlab.com/anfaux/pixel-font)] [[Font
              Creator](https://pixelfont.foxriot.com/)] Converts text to matrix
              font `https://api.foxriot.com/pixelfont?m=hello`
              <div style={styles.item}>
                <div style={styles.itemImg}>
                  <img
                    style={{ width: '5rem', height: '5rem' }}
                    alt=""
                    src="https://gitlab.com/uploads/-/system/project/avatar/30902055/Screen_Shot_2021-11-01_at_5.13.15_PM.png?width=64"
                  />
                </div>
                <div style={{ textTransform: 'uppercase', fontWeight: 900 }}>
                  Truism
                </div>
              </div>
              [[Source](https://gitlab.com/feralresearch/truisms-api)] Issues a
              random truism `https://api.secretvenue.org/truisms`
            </div>
            <div style={{ flexGrow: 1 }} />
          </div>
        </div>
        <div style={styles.bottomDash} />
      </div>
    </div>
  );
};
export default Code;

const defaultStyles = {
  item: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  itemImg: {
    marginRight: '1rem',
    borderRadius: '5rem',
    overflow: 'hidden',
    height: '5rem',
    width: '5rem'
  },
  background: {
    position: 'fixed',
    height: '100rem',
    opacity: 0.3,
    marginTop: '-25rem auto 0 auto',
    backgroundImage: 'url(assets/img/texture.png'
  },
  bodyCopy: {
    color: 'white',
    maxWidth: '90vw',
    fontFamily: 'BasierRegular',
    minHeight: '30rem'
  },
  wrapper: {
    position: 'relative',
    maxWidth: '100%',
    minHeight: '100vh',
    maxHeight: '100vh',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  container: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    width: '600px',
    margin: '7rem auto 0 auto',
    lineHeight: '2rem'
  },
  chroma: {
    position: 'relative',
    backgroundImage: "url('assets/img/chroma.png')",
    padding: 0,
    margin: 0,
    backgroundSize: 'contain'
  },
  scriptHeader: {
    position: 'relative',
    left: '1rem',
    color: 'white',
    background: 'black',
    mixBlendMode: 'overlay',
    fontSize: '10rem',
    lineHeight: '10rem',
    height: '10rem',
    fontFamily: 'Dancing Script',
    fontWeight: 900
  },
  projectListBackground: {
    opacity: 0.1,
    margin: 'auto',
    borderTop: '3px dotted black',
    position: 'absolute',
    transform: 'rotateZ(2deg)',
    left: '-31rem',
    minHeight: '100vh',
    width: '200vw',
    background: 'white',
    backgroundSize: 'cover',
    backgroundPosition: '-2rem -4rem',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(assets/img/e99a6961-0410-4429-a944-fb1909e6ef9e.png)'
  },
  projectList: {
    margin: 'auto',
    marginTop: '4rem'
  },
  topDash: {
    borderBottom: '3px dashed white',
    width: '100vw',
    margin: '1rem 0 7rem -11rem',
    transform: 'rotateZ(-3deg)'
  },
  bottomDash: {
    borderBottom: '3px dashed white',
    width: '100vw',
    margin: '7rem 0 8rem -11rem',
    transform: 'rotateZ(3deg)'
  }
};
