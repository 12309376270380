import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';
import { IconContext } from 'react-icons';

const ProjectNavigation = ({ setFilter, showNav = true }) => {
  const navigate = useNavigate();
  return (
    <div>
      {showNav && (
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            padding: '2rem 0 0 .5rem',
            color: 'white',
            fontFamily: 'BasierRegular',
            margin: '1rem auto 0 auto',
            textTransform: 'uppercase',
            fontSize: '.8rem',
            fontWeight: 900,
            alignItems: 'center'
          }}
        >
          <div
            onClick={() => (document.location.href = '/')}
            style={{ margin: '0 .25rem 0 0' }}
          >
            Home
          </div>
          <div style={{ margin: '0 .25rem ' }}>•</div>
          <div
            onClick={() => navigate('/projects')}
            style={{ margin: '0 .25rem 0 0' }}
          >
            Projects
          </div>
          {/*
          <div style={{ margin: '0 .25rem ' }}>•</div>
          <div
            onClick={() => navigate('/pieces')}
            style={{ margin: '0 .25rem' }}
          >
            Pieces
        </div>
        */}
          <div style={{ margin: '0 .25rem' }}>•</div>
          <div onClick={() => navigate('/code')} style={{ margin: '0 .25rem' }}>
            Code
          </div>
        </div>
      )}
      {setFilter && (
        <div
          style={{
            display: 'flex',
            zIndex: 10,
            position: 'relative',
            margin: '0 .5rem'
          }}
        >
          <input
            style={styles.input}
            onChange={(e) => setFilter(e.target.value)}
            type="text"
            placeholder="Project Search..."
          />
          <div style={{ position: 'absolute', right: '.4rem', top: '1.6rem' }}>
            <IconContext.Provider
              value={{
                color: 'grey',
                size: '2rem'
              }}
            >
              <AiOutlineSearch />
            </IconContext.Provider>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectNavigation;

const styles = {
  input: {
    appearance: 'none',
    color: '#8900f2',
    background: '#0000003b',
    margin: '1rem 0 0 0',
    width: 'calc(100% - 1rem)',
    height: '2rem',
    padding: '0.5rem',
    fontSize: '1rem',
    fontWeight: 900,
    borderRadius: '0.3rem',
    border: '1px solid white',
    textTransform: 'uppercase'
  }
};
