import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProjectLink from 'components/ProjectLink';
import ProjectNavigation from 'components/ProjectNavigation';
import data from '../projectList.json';
import ChromaHeader from 'components/ChromaHeader';

const ProjectListing = ({ showNav = true }) => {
  const [projectList, setProjectList] = useState(data);
  const [filter, setFilter] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (filter?.trim().length > 0) {
      setProjectList((projectList) =>
        projectList.filter(
          (item) =>
            item.title.toLowerCase().includes(filter) ||
            item.tags.toLowerCase().includes(filter)
        )
      );
    } else {
      setProjectList(data);
    }
  }, [filter]);

  return (
    <div style={{ position: 'relative' }}>
      <div style={styles.projectListBackground} />
      <div style={styles.projectList}>
        <div
          style={{
            ...styles.bodyCopy,
            position: 'relative',
            zIndex: 5,
            margin: 'auto',
            maxWidth: '30rem'
          }}
        >
          {/*<div>
            <ChromaHeader style={{ height: '5rem', marginTop: '2rem' }}>
              Projects
            </ChromaHeader>
        </div>*/}
          <ProjectNavigation setFilter={setFilter} showNav={showNav} />
          {projectList.length === 0 && (
            <div style={{ margin: '1rem 0 0 0.5rem', textAlign: 'center' }}>
              No matches found
            </div>
          )}
        </div>
        <div style={{ marginTop: '1rem' }}>
          {projectList?.map((item) => (
            <div key={item.id}>
              <ProjectLink item={item} navigate={navigate} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default ProjectListing;

const styles = {
  bodyCopy: {
    color: 'white',
    maxWidth: '90vw',
    fontFamily: 'BasierRegular'
  },
  projectListBackground: {
    opacity: 0.1,
    margin: 'auto',
    borderTop: '3px dotted black',
    position: 'absolute',
    transform: 'rotateZ(2deg)',
    left: '-31rem',
    minHeight: '100vh',
    width: '200vw',
    background: 'white',
    backgroundSize: 'cover',
    backgroundPosition: '-2rem -4rem',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(assets/img/e99a6961-0410-4429-a944-fb1909e6ef9e.png)'
  },
  projectList: {
    margin: 'auto'
  }
};
