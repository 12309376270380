import React, { useState, useEffect, useRef } from 'react';
import ChromaHeader from 'components/ChromaHeader';
import ProjectNavigation from 'components/ProjectNavigation';
const breakPoint = 600;

const Pieces = () => {
  const [isSmol, setIsSmol] = useState(false);
  const containerRef = useRef();

  let styles = {
    ...defaultStyles,
    scriptHeader: {
      ...defaultStyles.scriptHeader,
      fontSize: isSmol ? '7rem' : '10rem'
    },
    pageChromaHeader: {
      ...defaultStyles.chroma,
      height: '20rem',
      left: isSmol ? '2rem' : '-2rem',
      mixBlendMode: 'screen',
      margin: '-2rem'
    }
  };

  useEffect(() => {
    const width = window.innerWidth;
    setIsSmol(width <= breakPoint);
    window.addEventListener(
      'resize',
      () => {
        const width = window.innerWidth;
        setIsSmol(width <= breakPoint);
      },
      false
    );
  }, []);

  return (
    <div style={styles.wrapper} ref={containerRef}>
      <div style={styles.background}>
        <img alt="texture" src="assets/img/texture.png" />
      </div>
      <div>
        <div style={{ position: 'fixed', top: 0, zIndex: 5 }}>
          <ProjectNavigation />
          <div style={styles.topDash} />
        </div>
        <div style={styles.container}>
          <div style={styles.pageChromaHeader}>
            <div style={styles.scriptHeader}>Pieces</div>
          </div>

          <ChromaHeader style={{ transform: 'rotateZ(-1deg)' }}>
            Make It Go
          </ChromaHeader>

          <div style={{ display: 'flex', maxWidth: '100vw' }}>
            <div style={{ flexGrow: 1 }} />
            <div style={styles.bodyCopy}>content</div>
            <div style={{ flexGrow: 1 }} />
          </div>
        </div>
        <div style={styles.bottomDash} />
      </div>
    </div>
  );
};
export default Pieces;

const defaultStyles = {
  background: {
    position: 'fixed',
    height: '100rem',
    opacity: 0.3,
    marginTop: '-25rem auto 0 auto',
    backgroundImage: 'url(assets/img/texture.png'
  },
  bodyCopy: {
    color: 'white',
    maxWidth: '90vw',
    fontFamily: 'BasierRegular',
    minHeight: '30rem'
  },
  wrapper: {
    position: 'relative',
    maxWidth: '100%',
    minHeight: '100vh',
    maxHeight: '100vh',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  container: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    width: '600px',
    margin: '7rem auto 0 auto',
    lineHeight: '2rem'
  },
  chroma: {
    position: 'relative',
    backgroundImage: "url('assets/img/chroma.png')",
    padding: 0,
    margin: 0,
    backgroundSize: 'contain'
  },
  scriptHeader: {
    position: 'relative',
    left: '1rem',
    color: 'white',
    background: 'black',
    mixBlendMode: 'overlay',
    fontSize: '10rem',
    lineHeight: '10rem',
    height: '10rem',
    fontFamily: 'Dancing Script',
    fontWeight: 900
  },
  projectListBackground: {
    opacity: 0.1,
    margin: 'auto',
    borderTop: '3px dotted black',
    position: 'absolute',
    transform: 'rotateZ(2deg)',
    left: '-31rem',
    minHeight: '100vh',
    width: '200vw',
    background: 'white',
    backgroundSize: 'cover',
    backgroundPosition: '-2rem -4rem',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(assets/img/e99a6961-0410-4429-a944-fb1909e6ef9e.png)'
  },
  projectList: {
    margin: 'auto',
    marginTop: '4rem'
  },
  topDash: {
    borderBottom: '3px dashed white',
    width: '100vw',
    margin: '1rem 0 7rem -11rem',
    transform: 'rotateZ(-3deg)'
  },
  bottomDash: {
    borderBottom: '3px dashed white',
    width: '100vw',
    margin: '7rem 0 8rem -11rem',
    transform: 'rotateZ(3deg)'
  }
};
