import React, { useState } from 'react';

const ProjectLink = ({ item, navigate }) => {
  const [isHovered, setIsHovered] = useState(false);
  if (!item.isPublished) return null;
  return (
    <div
      style={{
        position: 'relative',
        overflow: 'hidden',
        zIndex: 1,
        margin: '2rem'
      }}
    >
      <div
        onClick={() => (document.location.href = item.url)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center'
        }}
      >
        <div
          onMouseOver={() => setIsHovered(true)}
          onMouseOut={() => setIsHovered(false)}
          style={{
            margin: 'auto',
            width: '10rem',
            height: '10rem',
            borderRadius: '10rem',
            overflow: 'hidden'
          }}
        >
          <img
            draggable={false}
            alt={item.title}
            style={{
              width: '11rem',
              height: '11rem',
              objectFit: 'cover',
              margin: '-.5rem',
              transition: 'all .2s ease-in-out',
              transform: isHovered ? 'scale(1.05)' : 'scale(1.0)'
            }}
            src={`assets/projects/${item.id}.png`}
          />
        </div>
        <div style={styles.itemTitle}>{item.title}</div>
      </div>
    </div>
  );
};

export default ProjectLink;
const styles = {
  itemTitle: {
    color: 'white',
    maxWidth: '90vw',
    fontFamily: 'BasierRegular',
    margin: '1rem auto 0 auto',
    textTransform: 'uppercase',
    fontSize: '.8rem',
    fontWeight: 900,
    maxWidth: '10rem',
    textAlign: 'center'
  }
};
